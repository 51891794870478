import 'wdyr';
import '@/styles/globals.scss';
import 'dayjs/locale/fr';
import '@/common/i18n';

import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import type {AppProps} from 'next/app';
import {useEffect} from 'react';
import TagManager from 'react-gtm-module';
import {QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';

import Error from '@/components/Error';
import {AuthProvider} from '@/hooks/useAuth';
import {ProjectLastSeenDatesProvider} from '@/hooks/useProjectsLastSeenDates';
import {queryClient} from '@/hooks/useReactQuery';
import {StoreProvider} from '@/hooks/useStore';

dayjs.extend(updateLocale);

dayjs.updateLocale('fr', {
  monthsShort: [
    'Jan',
    'Fev',
    'Mar',
    'Avr',
    'Mai',
    'Juin',
    'Juil',
    'Août',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
});

function MyApp({Component, pageProps}: AppProps): JSX.Element {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      TagManager.initialize({gtmId: process.env.NEXT_PUBLIC_GTM_ID});
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider>
        <AuthProvider>
          <ProjectLastSeenDatesProvider>
            <Component {...pageProps} />
            <Error />
          </ProjectLastSeenDatesProvider>
        </AuthProvider>
      </StoreProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default MyApp;
