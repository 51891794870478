export enum modalTypes {
  COMPANY = 'COMPANY',
  LOAN = 'LOAN',
}

export enum sessionStorageKeys {
  LAST_SEEN_PROJECT = 'folde__last-seen-project',
}

export enum localStorageKeys {
  PROJECTS_LAST_SEEN_DATES = 'folde__projects-last-seen-dates',
}

export enum paths {
  INDEX = '/',
  LOGIN = '/login',
  PROJECTS = '/projects',
  SETTINGS = '/settings',
  SIGN_UP = '/signup',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  BROKER = '/broker',
  ACTION = '/action',
}

export enum translationNamespaces {
  GLOBAL = 'global',
  ERRORS = 'errors',
  USER = 'user',
  PROJECT = 'project',
  REGISTRATION = 'registration',
  UPLOAD_FORM = 'uploadForm',
  MODAL = 'modal',
  ENTERPRISE = 'enterprise',
  BROKER_DASHBOARD = 'brokerDashboard',
}

export enum folderStatus {
  EMPTY = 'EMPTY',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export enum uploadForms {
  ID_PROOF = 'idProof',
  WEDDING_PROOF = 'weddingProof',
  CIVIL_PARTNERSHIP_PROOF = 'civilPartnershipProof',
  CIVIL_PARTNERSHIP_DISSOLUTION_PROOF = 'civilPartnershipDissolutionProof',
  DIVORCE_AGREEMENT = 'divorceAgreement',
  PAYSLIPM1 = 'paySlipM1',
  PAYSLIPM2 = 'paySlipM2',
  PAYSLIPM3 = 'paySlipM3',
  PAYSLIP_LAST_DECEMBER = 'paySlipLastDecember',
  BONUS_PROOF = 'bonusProof',
  CONTRACT_PROOF = 'contractProof',
  LAST_AMENDMENT_CONTRACT_PROOF = 'lastAmendmentContractProof',
  ESTABLISHMENT_DECREE = 'establishmentDecree',
  EMPLOYER_CERTIFICATE = 'employerCertificate',
  RESIDENCY_PROOF = 'residencyProof',
  PROPERTY_TAX = 'propertyTax',
  ACCOMMODATION_CERTIFICATE = 'accommodationCertificate',
  RESIDENCY_PROOF_HOST = 'residencyProofHost',
  ID_PROOF_HOST = 'idProofHost',
  RENT_RECEIPT = 'rentReceipt',
  RENTAL_LEASE_LAST_TWO_YEARS = 'rentalLeaseLastTwoYears',
  OWNERSHIP_CERTIFICATE = 'ownershipCertificate',
  ACCEPTED_OFFER = 'acceptedOffer',
  NOTICE_OF_ASSESSMENT = 'noticeOfAssessment',
  BANK_STATEMENT_M1 = 'bankStatementM1',
  BANK_STATEMENT_M2 = 'bankStatementM2',
  BANK_STATEMENT_M3 = 'bankStatementM3',
  COST_STATEMENT = 'costStatement',
  FAMILY_RECORD_BOOK = 'familyRecordBook',
  EXPENSE_PROOF = 'expenseProof',
  SAVING_PROOF = 'savingProof',
  REAL_ESTATE_PROPERTY_TAX = 'realEstatePropertyTax',
  REVENU_PROOF = 'revenuProof',
  LOAN_PROOF = 'loanProof',
  LOAN_OFFER = 'loanOffer',
  AMORTISATION_TABLE = 'amortisationTable',
  ESTIMATION_RENOVATION = 'estimationRenovation',
  ENERGY_PERFORMANCE_DIAGNOSTIC = 'energyPerformanceDiagnostic',
  NOTARIAL_CERTIFICATE = 'notarialCertificate',
  RESERVATION_CONTRACT = 'reservationContract',
  SELECTED_PROVIDER_ESTIMATE = 'selectedProviderEstimate',
  SELECTED_SUPERVISOR_ESTIMATE = 'selectedSupervisorEstimate',
  PROJECT_SUPERVISOR_CONTRACT = 'projectSupervisorContract',
  SIGNED_PLAN = 'signedPlan',
  CONSTRUCTION_CONTRACT = 'constructionContract',
  BUILDING_PERMIT = 'buildingPermit',
  SALE_AGREEMENT = 'saleAgreement',
  CHILD_ALLOWANCE_PROOF = 'childAllowanceProof',
  BALANCESHEETA1 = 'balanceSheetA1',
  BALANCESHEETA2 = 'balanceSheetA2',
  BALANCESHEETA3 = 'balanceSheetA3',
  COMPANYSTATUS = 'companyStatus',
  COMPANYIDENTIFICATION = 'companyIdentification',
  RENTALLEASEREALESTATE = 'rentalLeaseRealEstate',
  DECLARATIONOFREALESTATEINCOME = 'declarationOfRealEstateIncome',
  OWNERSHIP_CERTIFICATE_REALESTATE = 'ownershipCertificateRealEstate',
  RENTAL_VALUE_CERTIFICATE = 'rentalValueCertificate',
  ACCEPTED_OFFER_REALESTATE = 'acceptedOfferRealEstate',
  TURNOVERCERTIFICATET1 = 'turnoverCertificateT1',
  TURNOVERCERTIFICATET2 = 'turnoverCertificateT2',
  TURNOVERCERTIFICATET3 = 'turnoverCertificateT3',
  TURNOVERCERTIFICATET4 = 'turnoverCertificateT4',
  PENSION_NOTICE = 'pensionNotice',
}

export enum civility {
  MISSUS = 'MISSUS',
  MISTER = 'MISTER',
}

export enum maritalStatus {
  SINGLE = 'SINGLE',
  LIVING_TOGETHER = 'LIVING_TOGETHER',
  CIVIL_PARTNERSHIP = 'CIVIL_PARTNERSHIP',
  MARRIED = 'MARRIED',
  SEPARATED = 'SEPARATED',
  PENDING_DIVORCE = 'PENDING_DIVORCE',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
}

export enum weddingContract {
  NONE = 'NONE',
  FULL_COMMUNITY = 'FULL_COMMUNITY',
  SEPARATE = 'SEPARATE',
  COMMUNITY_OF_PROPERTY = 'COMMUNITY_OF_PROPERTY',
  COMMUNITY_REDUCED_ACQUESTS_CONTRACT = 'COMMUNITY_REDUCED_ACQUESTS_CONTRACT',
  PARTICIPATION_ACQUESTS = 'PARTICIPATION_ACQUESTS',
}

export enum professionnalSituation {
  PRIVATE_SECTOR_EMPLOYEE = 'PRIVATE_SECTOR_EMPLOYEE',
  PUBLIC_SECTOR_EMPLOYEE = 'PUBLIC_SECTOR_EMPLOYEE',
  INDEPENDANT = 'INDEPENDANT',
  SEEKING_EMPLOYMENT = 'SEEKING_EMPLOYMENT',
  RETIRED = 'RETIRED',
  NONE = 'NONE',
}

export enum jobStatus {
  INDIVIDUAL_CONTRACTOR = 'INDIVIDUAL_CONTRACTOR',
  CHIEF_EXECUTIVE = 'CHIEF_EXECUTIVE',
  MAJORITY_MANAGER_SARL = 'MAJORITY_MANAGER_SARL',
  MINORITY_MANAGER = 'MINORITY_MANAGER',
  NON_ASSOCIATE_MANAGER = 'NON_ASSOCIATE_MANAGER',
  MANAGER_EURL = 'MANAGER_EURL',
  ASSOCIATE_SNC = 'ASSOCIATE_SNC',
}

export enum employmentContractPrivate {
  FIXED_TERM_CONTRACT = 'FIXED_TERM_CONTRACT',
  PERMANENT_CONTRACT = 'PERMANENT_CONTRACT',
  TEMPORARY_CONTRACT = 'TEMPORARY_CONTRACT',
}

export enum employmentContractPublic {
  HOLDER = 'HOLDER',
  INTERN = 'INTERN',
  CONTRACTUAL_FIXED_TERM_CONTRACT = 'CONTRACTUAL_FIXED_TERM_CONTRACT',
  CONTRACTUAL_PERMANENT_CONTRACT = 'CONTRACTUAL_PERMANENT_CONTRACT',
}

export enum legalForm {
  EI = 'EI',
  EIRL = 'EIRL',
  EURL = 'EURL',
  SARL = 'SARL',
  SA = 'SA',
  SASU = 'SASU',
  SAS = 'SAS',
  SNC = 'SNC',
  SCOP = 'SCOP',
  SCA = 'SCA',
  SCS = 'SCS',
  SELEURL = 'SELEURL',
  SELARL = 'SELARL',
  SELASU = 'SELASU',
  SELAS = 'SELAS',
  SELCA = 'SELCA',
  CIVIL_SOCIETY = 'CIVIL_SOCIETY',
}

export enum housingCondition {
  OWNER = 'OWNER',
  TENANT = 'TENANT',
  ACCOMODATED_FREE_OF_CHARGE = 'ACCOMODATED_FREE_OF_CHARGE',
}

export enum loanInvolved {
  NONE = 'NONE',
  BANK_LOAN = 'BANK_LOAN',
  PTZ = 'PTZ',
  ECO_PTZ = 'ECO_PTZ',
  EMPLOYER_LOAN = 'EMPLOYER_LOAN',
}

export enum taxCondition {
  NONE = 'NONE',
  TAX_SHARED_WITH_BORROWER = 'TAX_SHARED_WITH_BORROWER',
  INDIVIDUAL_TAX = 'INDIVIDUAL_TAX',
}

export enum causeForNoTaxReport {
  ATTACHED_TO_ANOTHER_HOME = 'ATTACHED_TO_ANOTHER_HOME',
  OUTSIDE_FRANCE = 'OUTSIDE_FRANCE',
}

export enum parentalCustody {
  SHARED_CUSTODY = 'SHARED_CUSTODY',
  SOLE_CUSTODY = 'SOLE_CUSTODY',
  NO_CUSTODY = 'NO_CUSTODY',
}

export enum childAllowance {
  CHILD_ALLOWANCE_PAYABLE = 'CHILD_ALLOWANCE_PAYABLE',
  CHILD_ALLOWANCE_RECEIVED = 'CHILD_ALLOWANCE_RECEIVED',
  NONE = 'NONE',
}

export enum expenseType {
  CURRENT_ACCOMMODATION = 'CURRENT_ACCOMMODATION',
  RENT = 'RENT',
  CHILD_ALLOWANCE_PAYABLE = 'CHILD_ALLOWANCE_PAYABLE',
  LEASING = 'LEASING',
}

export enum savingType {
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  TERM_ACCOUNT = 'TERM_ACCOUNT',
  HOUSING_SAVING_ACCOUNT = 'HOUSING_SAVING_ACCOUNT',
  TRADING_ACCOUNT = 'TRADING_ACCOUNT',
  SAVING_TIME_ACCOUNT = 'SAVING_TIME_ACCOUNT',
  MADELIN_CONTRACT = 'MADELIN_CONTRACT',
  BANKBOOK_A = 'BANKBOOK_A',
  BANKBOOK_LDDS = 'BANKBOOK_LDDS',
  BANKBOOK_LEP = 'BANKBOOK_LEP',
  BANKBOOK_YOUNG = 'BANKBOOK_YOUNG',
  EMPLOYEE_SAVING = 'EMPLOYEE_SAVING',
  BANKBOOK_PEA = 'BANKBOOK_PEA',
  HOUSING_SAVING = 'HOUSING_SAVING',
  ENTERPRISE_RETIREMENT_SAVING = 'ENTERPRISE_RETIREMENT_SAVING',
  INDIVIDUAL_RETIREMENT_SAVING = 'INDIVIDUAL_RETIREMENT_SAVING',
  POPULAR_RETIREMENT_SAVING = 'POPULAR_RETIREMENT_SAVING',
  COLLECTIVE_RETIREMENT_SAVING = 'COLLECTIVE_RETIREMENT_SAVING',
  SCPI = 'SCPI',
  OTHER_BANKBOOK = 'OTHER_BANKBOOK',
}

export enum objectRealEstate {
  SECONDARY_RESIDENCE = 'SECONDARY_RESIDENCE',
  RENTAL_INVESTMENT = 'RENTAL_INVESTMENT',
  OTHER = 'OTHER',
}

export enum typeRealEstate {
  APARTMENT = 'APARTMENT',
  HOUSE = 'HOUSE',
  BUILDING = 'BUILDING',
  PARKING = 'PARKING',
  GARAGE = 'GARAGE',
  COMMERCIALPREMISES = 'COMMERCIALPREMISES',
  BOX = 'BOX',
  LAND = 'LAND',
  OTHER = 'OTHER',
}

export enum revenuType {
  JOB = 'JOB',
  REALESTATE = 'REAL_ESTATE',
  CHILD_ALLOWANCE_RECEIVED = 'CHILD_ALLOWANCE_RECEIVED',
  PENSION = 'PENSION',
  FAMILY_ALLOWANCE = 'FAMILY_ALLOWANCE',
  ELECTED_ALLOWANCE = 'ELECTED_ALLOWANCE',
}

export enum objectLoan {
  REALESTATE = 'REALESTATE',
  RESTORATION_WORK = 'RESTORATION_WORK',
  VEHICLE = 'VEHICLE',
  CONSUMER_CREDIT = 'CONSUMER_CREDIT',
  OTHER = 'OTHER',
}

export enum loanType {
  BANK_LOAN = 'BANK_LOAN',
  PTZ = 'PTZ',
  ECO_PTZ = 'ECO_PTZ',
  EMPLOYER_LOAN = 'EMPLOYER_LOAN',
  RELAY_LOAN = 'RELAY_LOAN',
  IN_FINE_LOAN = 'IN_FINE_LOAN',
  HOME_SAVINGS_LOAN = 'HOME_SAVINGS_LOAN',
}

export enum operationProject {
  ACQUISITION = 'ACQUISITION',
  RENEGOCIATION = 'RENEGOCIATION',
  BALANCE = 'BALANCE',
  RENOVATION_WORK = 'RENOVATION_WORK',
}

export enum operationProjectDetail {
  LAND = 'LAND',
  LAND_AND_CONSTRUCTION = 'LAND_AND_CONSTRUCTION',
  CONSTRUCTION_ONLY = 'CONSTRUCTION_ONLY',
  EXISTING_ACQUISITION = 'EXISTING_ACQUISITION',
  NEW_ACQUISITION = 'NEW_ACQUISITION',
}

export enum objectProject {
  PRINCIPAL_RESIDENCE = 'PRINCIPAL_RESIDENCE',
  SECONDARY_RESIDENCE = 'SECONDARY_RESIDENCE',
  RENTAL_INVESTMENT = 'RENTAL_INVESTMENT',
}

export enum typeProperty {
  APARTMENT = 'APARTMENT',
  HOUSE = 'HOUSE',
  BUILDING = 'BUILDING',
  PARKING = 'PARKING',
  GARAGE = 'GARAGE',
  COMMERCIALPREMISES = 'COMMERCIALPREMISES',
  BOX = 'BOX',
  OTHER = 'OTHER',
}

export enum typeNewAcquisition {
  TURNKEY = 'TURNKEY',
  OFFPLAN = 'OFFPLAN',
}

export enum constructionType {
  HOUSE = 'HOUSE',
  BUILDING = 'BUILDING',
  OTHER = 'OTHER',
}

export enum constructionMode {
  HOMEBUILDER = 'HOMEBUILDER',
  PROJECTSUPERVISOR = 'PROJECTSUPERVISOR',
  SELFCONSTRUCTION = 'SELFCONSTRUCTION',
}

export enum agencyInvolved {
  COSTS_BUYER = 'COSTS_BUYER',
  COSTS_SELLER = 'COSTS_SELLER',
  NONE = 'NONE',
}

export enum bankName {
  ALLIANZ_BANQUE = 'ALLIANZ_BANQUE',
  AMERICAN_EXPRESS_FRANCE = 'AMERICAN_EXPRESS_FRANCE',
  ARKEA_BANQUE_PRIVEE = 'ARKEA_BANQUE_PRIVEE',
  AXA_BANQUE = 'AXA_BANQUE',
  BANQUE_BCP = 'BANQUE_BCP',
  BANQUE_CASINO = 'BANQUE_CASINO',
  BANQUE_CHALUS = 'BANQUE_CHALUS',
  BANQUE_COURTOIS = 'BANQUE_COURTOIS',
  BANQUE_EUROPEENNE_DU_CREDIT_MUTUEL = 'BANQUE_EUROPEENNE_DU_CREDIT_MUTUEL',
  BANQUE_DE_LA_REUNION = 'BANQUE_DE_LA_REUNION',
  BANQUE_DE_SAVOIE = 'BANQUE_DE_SAVOIE',
  BANQUE_DE_MONACO = 'BANQUE_DE_MONACO',
  BANQUE_FEDERALE_MUTUALISTE = 'BANQUE_FEDERALE_MUTUALISTE',
  BANQUE_KOLB = 'BANQUE_KOLB',
  BANQUE_LAYDERNIER = 'BANQUE_LAYDERNIER',
  BANQUE_NUGER = 'BANQUE_NUGER',
  BANQUE_PALATINE_PARTICULIERS = 'BANQUE_PALATINE_PARTICULIERS',
  BANQUE_PALATINE_ENTREPRISES_PROF_IMMOBILIERES = 'BANQUE_PALATINE_ENTREPRISES_PROF_IMMOBILIERES',
  BANQUE_POPULAIRE = 'BANQUE_POPULAIRE',
  BANQUE_RHONE_ALPES = 'BANQUE_RHONE_ALPES',
  BANQUE_SBE = 'BANQUE_SBE',
  BANQUE_TARNEAUD = 'BANQUE_TARNEAUD',
  BANQUE_TRANSATLANTIQUE = 'BANQUE_TRANSATLANTIQUE',
  BARCLAYS = 'BARCLAYS',
  BFOR_BANK = 'BFOR_BANK',
  BNP_PARIBAS_PARTICULIER = 'BNP_PARIBAS_PARTICULIER',
  BNP_PRIVEE = 'BNP_PRIVEE',
  BNP_PARIBAS_PRO = 'BNP_PARIBAS_PRO',
  BNP_NET_ENTREPRISES = 'BNP_NET_ENTREPRISES',
  BNP_GUADELOUPE = 'BNP_GUADELOUPE',
  BNP_GUYANE = 'BNP_GUYANE',
  BNP_MARTINIQUE = 'BNP_MARTINIQUE',
  BNP_REUNION = 'BNP_REUNION',
  BOURSORAMA_BANQUE = 'BOURSORAMA_BANQUE',
  BRED = 'BRED',
  CAISSE_EPARGNE = 'CAISSE_EPARGNE',
  CARREFOUR_BANQUE = 'CARREFOUR_BANQUE',
  CETELEM = 'CETELEM',
  CORTAL_CONSORS = 'CORTAL_CONSORS',
  CIC = 'CIC',
  COMPTE_NICKEL = 'COMPTE_NICKEL',
  CREDIT_AGRICOLE = 'CREDIT_AGRICOLE',
  CREDIT_COOPERATIF = 'CREDIT_COOPERATIF',
  CREDIT_DU_NORD = 'CREDIT_DU_NORD',
  CREDIT_FONCIER = 'CREDIT_FONCIER',
  CREDIT_MARITIME = 'CREDIT_MARITIME',
  CREDIT_MUTUEL = 'CREDIT_MUTUEL',
  CZAM = 'CZAM',
  DISPOBANK = 'DISPOBANK',
  FORTIS_BANQUE = 'FORTIS_BANQUE',
  FORTUNEO = 'FORTUNEO',
  GROUPAMA_BANQUE = 'GROUPAMA_BANQUE',
  HELLO_BANK = 'HELLO_BANK',
  HSBC = 'HSBC',
  ING_DIRECT = 'ING_DIRECT',
  AGENCE_DIRECTE = 'AGENCE_DIRECTE',
  BANQUE_POSTALE = 'BANQUE_POSTALE',
  NEF = 'NEF',
  LCL = 'LCL',
  MONABANQ = 'MONABANQ',
  N26 = 'N26',
  NATAXIS = 'NATAXIS',
  ONEY = 'ONEY',
  ORANGE_BANK = 'ORANGE_BANK',
  PSA_BANQUE = 'PSA_BANQUE',
  REVOLUT = 'REVOLUT',
  RCI_BANQUE = 'RCI_BANQUE',
  SOCIETE_GENERALE = 'SOCIETE_GENERALE',
  SOCIETE_MARSEILLAISE_DE_CREDIT = 'SOCIETE_MARSEILLAISE_DE_CREDIT',
  UNILEND = 'UNILEND',
  VTB_BANK = 'VTB_BANK',
  YOMONI = 'YOMONI',
  OTHER = 'OTHER',
}

export enum lender {
  ALLIANZ_BANQUE = 'ALLIANZ_BANQUE',
  AMERICAN_EXPRESS_FRANCE = 'AMERICAN_EXPRESS_FRANCE',
  ARKEA_BANQUE_PRIVEE = 'ARKEA_BANQUE_PRIVEE',
  AXA_BANQUE = 'AXA_BANQUE',
  BANQUE_BCP = 'BANQUE_BCP',
  BANQUE_CASINO = 'BANQUE_CASINO',
  BANQUE_CHALUS = 'BANQUE_CHALUS',
  BANQUE_COURTOIS = 'BANQUE_COURTOIS',
  BANQUE_EUROPEENNE_DU_CREDIT_MUTUEL = 'BANQUE_EUROPEENNE_DU_CREDIT_MUTUEL',
  BANQUE_DE_LA_REUNION = 'BANQUE_DE_LA_REUNION',
  BANQUE_DE_SAVOIE = 'BANQUE_DE_SAVOIE',
  BANQUE_DE_MONACO = 'BANQUE_DE_MONACO',
  BANQUE_FEDERALE_MUTUALISTE = 'BANQUE_FEDERALE_MUTUALISTE',
  BANQUE_KOLB = 'BANQUE_KOLB',
  BANQUE_LAYDERNIER = 'BANQUE_LAYDERNIER',
  BANQUE_NUGER = 'BANQUE_NUGER',
  BANQUE_PALATINE_PARTICULIERS = 'BANQUE_PALATINE_PARTICULIERS',
  BANQUE_PALATINE_ENTREPRISES_PROF_IMMOBILIERES = 'BANQUE_PALATINE_ENTREPRISES_PROF_IMMOBILIERES',
  BANQUE_POPULAIRE = 'BANQUE_POPULAIRE',
  BANQUE_RHONE_ALPES = 'BANQUE_RHONE_ALPES',
  BANQUE_SBE = 'BANQUE_SBE',
  BANQUE_TARNEAUD = 'BANQUE_TARNEAUD',
  BANQUE_TRANSATLANTIQUE = 'BANQUE_TRANSATLANTIQUE',
  BARCLAYS = 'BARCLAYS',
  BFOR_BANK = 'BFOR_BANK',
  BNP_PARIBAS_PARTICULIER = 'BNP_PARIBAS_PARTICULIER',
  BNP_PRIVEE = 'BNP_PRIVEE',
  BNP_PARIBAS_PRO = 'BNP_PARIBAS_PRO',
  BNP_NET_ENTREPRISES = 'BNP_NET_ENTREPRISES',
  BNP_GUADELOUPE = 'BNP_GUADELOUPE',
  BNP_GUYANE = 'BNP_GUYANE',
  BNP_MARTINIQUE = 'BNP_MARTINIQUE',
  BNP_REUNION = 'BNP_REUNION',
  BOURSORAMA_BANQUE = 'BOURSORAMA_BANQUE',
  BRED = 'BRED',
  CAISSE_EPARGNE = 'CAISSE_EPARGNE',
  CARREFOUR_BANQUE = 'CARREFOUR_BANQUE',
  CETELEM = 'CETELEM',
  CORTAL_CONSORS = 'CORTAL_CONSORS',
  CIC = 'CIC',
  COMPTE_NICKEL = 'COMPTE_NICKEL',
  CREDIT_AGRICOLE = 'CREDIT_AGRICOLE',
  CREDIT_COOPERATIF = 'CREDIT_COOPERATIF',
  CREDIT_DU_NORD = 'CREDIT_DU_NORD',
  CREDIT_FONCIER = 'CREDIT_FONCIER',
  CREDIT_MARITIME = 'CREDIT_MARITIME',
  CREDIT_MUTUEL = 'CREDIT_MUTUEL',
  CZAM = 'CZAM',
  DISPOBANK = 'DISPOBANK',
  FORTIS_BANQUE = 'FORTIS_BANQUE',
  FORTUNEO = 'FORTUNEO',
  GROUPAMA_BANQUE = 'GROUPAMA_BANQUE',
  HELLO_BANK = 'HELLO_BANK',
  HSBC = 'HSBC',
  ING_DIRECT = 'ING_DIRECT',
  AGENCE_DIRECTE = 'AGENCE_DIRECTE',
  BANQUE_POSTALE = 'BANQUE_POSTALE',
  NEF = 'NEF',
  LCL = 'LCL',
  MONABANQ = 'MONABANQ',
  N26 = 'N26',
  NATAXIS = 'NATAXIS',
  ONEY = 'ONEY',
  ORANGE_BANK = 'ORANGE_BANK',
  PSA_BANQUE = 'PSA_BANQUE',
  PRO_BTP = 'PRO_BTP',
  REVOLUT = 'REVOLUT',
  RCI_BANQUE = 'RCI_BANQUE',
  SOCIETE_GENERALE = 'SOCIETE_GENERALE',
  SOCIETE_MARSEILLAISE_DE_CREDIT = 'SOCIETE_MARSEILLAISE_DE_CREDIT',
  UNILEND = 'UNILEND',
  VTB_BANK = 'VTB_BANK',
  OTHER = 'OTHER',
}

export enum savingBankName {
  ALLIANZ_BANQUE = 'ALLIANZ_BANQUE',
  AMERICAN_EXPRESS_FRANCE = 'AMERICAN_EXPRESS_FRANCE',
  AMUNDI = 'AMUNDI',
  ARKEA_BANQUE_PRIVEE = 'ARKEA_BANQUE_PRIVEE',
  AXA_BANQUE = 'AXA_BANQUE',
  BANQUE_BCP = 'BANQUE_BCP',
  BANQUE_CASINO = 'BANQUE_CASINO',
  BANQUE_CHALUS = 'BANQUE_CHALUS',
  BANQUE_COURTOIS = 'BANQUE_COURTOIS',
  BANQUE_EUROPEENNE_DU_CREDIT_MUTUEL = 'BANQUE_EUROPEENNE_DU_CREDIT_MUTUEL',
  BANQUE_DE_LA_REUNION = 'BANQUE_DE_LA_REUNION',
  BANQUE_DE_SAVOIE = 'BANQUE_DE_SAVOIE',
  BANQUE_DE_MONACO = 'BANQUE_DE_MONACO',
  BANQUE_FEDERALE_MUTUALISTE = 'BANQUE_FEDERALE_MUTUALISTE',
  BANQUE_KOLB = 'BANQUE_KOLB',
  BANQUE_LAYDERNIER = 'BANQUE_LAYDERNIER',
  BANQUE_NUGER = 'BANQUE_NUGER',
  BANQUE_PALATINE_PARTICULIERS = 'BANQUE_PALATINE_PARTICULIERS',
  BANQUE_PALATINE_ENTREPRISES_PROF_IMMOBILIERES = 'BANQUE_PALATINE_ENTREPRISES_PROF_IMMOBILIERES',
  BANQUE_POPULAIRE = 'BANQUE_POPULAIRE',
  BANQUE_RHONE_ALPES = 'BANQUE_RHONE_ALPES',
  BANQUE_SBE = 'BANQUE_SBE',
  BANQUE_TARNEAUD = 'BANQUE_TARNEAUD',
  BANQUE_TRANSATLANTIQUE = 'BANQUE_TRANSATLANTIQUE',
  BARCLAYS = 'BARCLAYS',
  BFOR_BANK = 'BFOR_BANK',
  BNP_PARIBAS_PARTICULIER = 'BNP_PARIBAS_PARTICULIER',
  BNP_PRIVEE = 'BNP_PRIVEE',
  BNP_PARIBAS_PRO = 'BNP_PARIBAS_PRO',
  BNP_NET_ENTREPRISES = 'BNP_NET_ENTREPRISES',
  BNP_GUADELOUPE = 'BNP_GUADELOUPE',
  BNP_GUYANE = 'BNP_GUYANE',
  BNP_MARTINIQUE = 'BNP_MARTINIQUE',
  BNP_REUNION = 'BNP_REUNION',
  BOURSORAMA_BANQUE = 'BOURSORAMA_BANQUE',
  BRED = 'BRED',
  CAISSE_EPARGNE = 'CAISSE_EPARGNE',
  CARREFOUR_BANQUE = 'CARREFOUR_BANQUE',
  CETELEM = 'CETELEM',
  CORTAL_CONSORS = 'CORTAL_CONSORS',
  CIC = 'CIC',
  COMPTE_NICKEL = 'COMPTE_NICKEL',
  CREDIT_AGRICOLE = 'CREDIT_AGRICOLE',
  CREDIT_COOPERATIF = 'CREDIT_COOPERATIF',
  CREDIT_DU_NORD = 'CREDIT_DU_NORD',
  CREDIT_FONCIER = 'CREDIT_FONCIER',
  CREDIT_MARITIME = 'CREDIT_MARITIME',
  CREDIT_MUTUEL = 'CREDIT_MUTUEL',
  CZAM = 'CZAM',
  DISPOBANK = 'DISPOBANK',
  FORTIS_BANQUE = 'FORTIS_BANQUE',
  FORTUNEO = 'FORTUNEO',
  GROUPAMA_BANQUE = 'GROUPAMA_BANQUE',
  HELLO_BANK = 'HELLO_BANK',
  HSBC = 'HSBC',
  ING_DIRECT = 'ING_DIRECT',
  AGENCE_DIRECTE = 'AGENCE_DIRECTE',
  BANQUE_POSTALE = 'BANQUE_POSTALE',
  NEF = 'NEF',
  LCL = 'LCL',
  MONABANQ = 'MONABANQ',
  N26 = 'N26',
  NATAXIS = 'NATAXIS',
  ONEY = 'ONEY',
  ORANGE_BANK = 'ORANGE_BANK',
  PSA_BANQUE = 'PSA_BANQUE',
  PRO_BTP = 'PRO_BTP',
  REVOLUT = 'REVOLUT',
  RCI_BANQUE = 'RCI_BANQUE',
  SOCIETE_GENERALE = 'SOCIETE_GENERALE',
  SOCIETE_MARSEILLAISE_DE_CREDIT = 'SOCIETE_MARSEILLAISE_DE_CREDIT',
  UNILEND = 'UNILEND',
  VTB_BANK = 'VTB_BANK',
  YOMONI = 'YOMONI',
  OTHER = 'OTHER',
}

export enum ErrorReason {
  ALREADY_EXISTING_ACCOUNT = 'ALREADY_EXISTING_ACCOUNT',
}
