import {createContext, ReactNode, useCallback, useContext, useMemo} from 'react';
import {useLocalStorage} from 'react-use';

import {localStorageKeys} from '@/common/enums';

type MarkProjectAsSeen = (projectId: string) => void;
export type ProjectsLastSeenDates = {[projectId: string]: number};
type ProjectsLastSeenDatesHookValue = [ProjectsLastSeenDates, MarkProjectAsSeen];

const ProjectLastSeenDatesContext = createContext<ProjectsLastSeenDatesHookValue | null>(null);

export const ProjectLastSeenDatesProvider = ({children}: {children: ReactNode}): JSX.Element => {
  const [projectsLastSeenDates, setProjectsLastSeenDates] = useLocalStorage<ProjectsLastSeenDates>(
    localStorageKeys.PROJECTS_LAST_SEEN_DATES,
    {}
  );

  const markAsSeen = useCallback(
    (projectId: string) => {
      // can't use func setState in `useLocalStorage`
      // because of https://github.com/streamich/react-use/pull/2046
      setProjectsLastSeenDates({...projectsLastSeenDates, [projectId]: Date.now()});
    },
    [setProjectsLastSeenDates, projectsLastSeenDates]
  );

  const value = useMemo<ProjectsLastSeenDatesHookValue>(
    () => [projectsLastSeenDates || {}, markAsSeen],
    [projectsLastSeenDates, markAsSeen]
  );

  return (
    <ProjectLastSeenDatesContext.Provider value={value}>
      {children}
    </ProjectLastSeenDatesContext.Provider>
  );
};

/**
 * Will return:
 * - a map of projectId > timestamp that gives the last time a project was marked as seen
 * - a function to mark a project as seen
 *
 * The data is stored in Local Storage and provided globally via the
 * provider `<ProjectLastSeenDatesProvider />`.
 */
function useProjectsLastSeenDates(): ProjectsLastSeenDatesHookValue {
  const value = useContext(ProjectLastSeenDatesContext);
  if (value === null) {
    throw new Error('ProjectLastSeenDatesProvider was not provided');
  }
  return value;
}

export default useProjectsLastSeenDates;
