import jwtDecode from 'jwt-decode';

import {TUserJWToken} from '@/common/types';

export const env = {
  isProd: process.env.NEXT_PUBLIC_PROD === 'true',
  isLocal: process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_CALL_PROD !== 'true',
};

export function notUndefined<T>(x: T | undefined): x is T {
  return x !== undefined;
}

export function getKeys<O extends Record<string, unknown>>(o: O): (keyof O)[] {
  return Object.keys(o) as Array<keyof O>;
}

export function labelFromArray(array: string[]): string {
  return array.join(' • ');
}

export function numberToLocaleString(
  x: number,
  options: {
    isCurrency?: boolean;
    hasDecimals?: boolean;
  } = {}
): string {
  if (isNaN(x)) return '';
  return new Intl.NumberFormat('fr-FR', {
    currency: options.isCurrency ? 'EUR' : undefined,
    minimumFractionDigits: options.hasDecimals ? 2 : 0,
    maximumFractionDigits: 2,
    style: options.isCurrency ? 'currency' : 'decimal',
  }).format(x);
}

export function decodeUserJWToken(token: string): TUserJWToken {
  return jwtDecode(token);
}

// https://advancedweb.hu/how-to-use-async-functions-with-array-filter-in-javascript/
export const asyncFilter = async <T>(
  arr: T[],
  predicate: (array: T) => Promise<boolean>
): Promise<T[]> => {
  const results = await Promise.all(arr.map(predicate));
  return arr.filter((_v, index) => results[index]);
};
