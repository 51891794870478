import 'firebase/auth';
import 'firebase/firebase-storage';

import firebaseClient from 'firebase/app';

import {env} from '@/helpers/utils';

const config = {
  dev: {
    apiKey: 'AIzaSyACb3R7RSCiJ5DFmlrNFC8EZ5OLNalmk8Y',
    authDomain: 'folde-dev.firebaseapp.com',
    projectId: 'folde-dev',
    storageBucket: 'folde-dev.appspot.com',
    messagingSenderId: '877076681806',
    appId: '1:877076681806:web:ba53d3582df50971345f1f',
    measurementId: 'G-HXF84JZ2L3',
  },
  prod: {
    apiKey: 'AIzaSyCVpv1jR91fs4-xfiwmAVamN7OqvmhAot4',
    authDomain: 'folde-prod.firebaseapp.com',
    projectId: 'folde-prod',
    storageBucket: 'folde-prod.appspot.com',
    messagingSenderId: '1002028104710',
    appId: '1:1002028104710:web:315e05e55add74197d5787',
  },
};

const CLIENT_CONFIG = env.isProd ? config.prod : config.dev;

if (!firebaseClient.apps.length) {
  firebaseClient.initializeApp(CLIENT_CONFIG);
  firebaseClient.auth().languageCode = 'fr';
}

if (typeof window !== 'undefined' && process.env.NODE_ENV === 'development') {
  firebaseClient.auth().setPersistence(firebaseClient.auth.Auth.Persistence.SESSION);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).firebase = firebaseClient;
}
export {firebaseClient};
