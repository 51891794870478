import {useRouter} from 'next/router';
import nookies from 'nookies';
import React, {createContext, useContext, useEffect, useState} from 'react';

import {paths} from '@/common/enums';
import {firebaseClient} from '@/common/firebaseClient';
import {decodeUserJWToken} from '@/helpers/utils';

import {queryClient} from './useReactQuery';

type Context = {
  user?: firebaseClient.User | null;
  isBroker?: boolean;
  signin(email: string, password: string): Promise<Context['user']>;
  signOut(): Promise<void>;
};

const AuthContext = createContext<Context>({
  signin: () => Promise.resolve(null),
  signOut: () => Promise.resolve(),
});

export function AuthProvider({children}: {children: React.ReactNode}): JSX.Element {
  const [user, setUser] = useState<Context['user']>();
  const [isBroker, setIsBroker] = useState<Context['isBroker']>();
  const router = useRouter();

  async function signin(email: string, password: string): Promise<Context['user']> {
    const userCredential = await firebaseClient.auth().signInWithEmailAndPassword(email, password);
    const {user} = userCredential;
    return user;
  }

  async function signOut() {
    firebaseClient.auth().signOut();
    queryClient.clear();
  }

  useEffect(() => {
    const unsubscribe = firebaseClient.auth().onIdTokenChanged(async (user) => {
      const options = {path: '/'};

      if (!user) {
        nookies.destroy(null, 'token', options);
        setUser(null);
        return;
      }

      const token = await user.getIdToken();
      nookies.set(null, 'token', token, options);
      setUser(user);

      const isBroker = !!decodeUserJWToken(token).isBroker;
      setIsBroker(isBroker);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user === null) {
      const authorizedPaths = [
        paths.SIGN_UP,
        paths.FORGOT_PASSWORD,
        paths.RESET_PASSWORD,
        paths.ACTION,
      ] as string[];

      if (!authorizedPaths.includes(router.pathname)) router.push('/login');
    }
  }, [user]);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      const user = firebaseClient.auth().currentUser;
      if (user) await user.getIdToken(true);
    }, 10 * 60 * 1000);

    return () => clearInterval(handle);
  }, []);

  return (
    <AuthContext.Provider value={{user, isBroker, signin, signOut}}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): Context => {
  return useContext(AuthContext);
};
