import {useStore} from '@/hooks/useStore';

import Error from './Error';

export const ConnectedError = (): JSX.Element | null => {
  const {store, dispatch} = useStore();

  if (store.status !== 'error') return null;

  return <Error message={store.error} onClose={() => dispatch({type: 'ok'})} />;
};

export default ConnectedError;
