import React, {createContext, useContext, useMemo, useReducer} from 'react';

import {modalTypes} from '@/common/enums';
import {UserLoan} from '@/common/types';

type Context = {
  store: Store;
  dispatch: (action: TAction) => void;
};

export type TAction =
  | {type: 'ok'}
  | {type: 'error'; error: string}
  | {type: 'openModal'; modal: Data['modal']}
  | {type: 'closeModal'};

type Store = {status: 'error'; error: string; data: Data} | {status: 'ok'; data: Data};

type Data = {
  modal: {
    type: modalTypes | null;
    defaultTab?: number;
    defaultTabKey?: UserLoan['key'];
  };
};

function reducer(store: Store, action: TAction): Store {
  switch (action.type) {
    case 'ok':
      return {status: 'ok', data: {...store.data}};
    case 'error':
      return {status: 'error', error: action.error, data: {...store.data}};
    case 'openModal':
      return {...store, data: {...store.data, modal: action.modal}};
    case 'closeModal':
      return {...store, data: {...store.data, modal: defaultStore.data.modal}};
    default:
      return store;
  }
}

const defaultStore = {
  status: 'ok' as const,
  data: {
    modal: {
      type: null,
    },
  },
};

const StateContext = createContext<Context>({store: defaultStore, dispatch: () => undefined});

export function StoreProvider({children}: {children: React.ReactNode}): JSX.Element {
  const [store, dispatch] = useReducer(reducer, defaultStore);
  const value = useMemo(() => ({store, dispatch}), [store, dispatch]);

  return <StateContext.Provider value={value}>{children}</StateContext.Provider>;
}

export const useStore = (): Context => {
  return useContext(StateContext);
};
