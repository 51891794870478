import classnames from 'classnames';
import React, {ForwardedRef} from 'react';

import {Props as IconProps} from '@/assets/icons';

import styles from './Button.module.scss';

interface CommonProps {
  children?: string;
  type?: 'button' | 'submit';
  className?: string;
  innerClassName?: string;
  disabled?: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

interface WithoutIconProps extends CommonProps {
  color: 'primary' | 'secondary' | 'danger';
  icon?: never;
}

interface WithIconProps extends CommonProps {
  color: 'tertiary';
  icon: React.ComponentType<IconProps>;
}

export type Props = WithoutIconProps | WithIconProps;

const Button = (
  {
    className,
    innerClassName,
    color,
    children,
    disabled,
    type = 'button',
    onClick,
    ...props
  }: WithoutIconProps | WithIconProps,
  ref: ForwardedRef<HTMLButtonElement>
): JSX.Element => {
  const Icon = color === 'tertiary' ? props.icon : undefined;
  return (
    <button
      ref={ref}
      type={type}
      disabled={disabled}
      // eslint-disable-next-line prettier/prettier
      className={classnames(
        styles.outer,
        styles[color],
        className
      )}
      onClick={onClick}
    >
      <span className={classnames(styles.inner, innerClassName)} tabIndex={-1}>
        {Icon && <Icon className={classnames(styles.icon, {[styles.isSingle]: !children})} />}
        {children}
      </span>
    </button>
  );
};

export default React.forwardRef(Button);
