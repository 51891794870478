import classnames from 'classnames';

import Button from '@/components/Button';
import Modal from '@/components/Modal';

import styles from './Error.module.scss';

export type TError = {
  message: string;
  onClose: () => void;
};

const Error = ({message, onClose}: TError): JSX.Element => {
  return (
    <Modal onClose={onClose} className={styles.modal}>
      <div
        className={classnames(styles.row, styles.message)}
        dangerouslySetInnerHTML={{__html: message}}
      />
      <div className={styles.row}>
        <Button color='secondary' onClick={onClose} className={styles.button}>
          Ok
        </Button>
      </div>
    </Modal>
  );
};

export default Error;
