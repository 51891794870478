import classNames from 'classnames';
import {useEffect, useRef} from 'react';

import {useOnClickOutside} from '@/hooks/useOnClickOutside';

import styles from './Modal.module.scss';

export type TModal = {
  title?: string;
  description?: string;
  className?: string;
  children: React.ReactNode;
  onClose?: () => void;
};

const Modal = ({title, description, children, className, onClose}: TModal): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(containerRef, onClose);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.container} ref={containerRef}>
        {title && <h1 className={styles.title}>{title}</h1>}
        {description && <p className={styles.description}>{description}</p>}
        <div className={styles.innerContainer}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
