import {AxiosResponse} from 'axios';
import {useMemo} from 'react';
import {UseMutateAsyncFunction, UseMutateFunction, useMutation, useQuery} from 'react-query';
import {QueryClient} from 'react-query';
import urljoin from 'url-join';

import axios, {AxiosError} from '@/common/axios';
import {ProjectDocument, UserDocument} from '@/common/types';

export enum queryId {
  PROJECTS = 'projects',
  PROJECT = 'project',
  USER = 'user',
}

enum ApiEndpoints {
  PROJECTS = '/projects',
  PROJECT = '/project',
  USER = '/user',
}

type TUseProjects = {
  isLoading: boolean;
  isFetching: boolean;
  projects: ProjectDocument[];
};

type TUseProject = {
  isLoading: boolean;
  isFetching: boolean;
  project: ProjectDocument | undefined;
};

export type TUseUpdateProject = {
  updateProject: UseMutateAsyncFunction<
    AxiosResponse<ProjectDocument>,
    AxiosError,
    ProjectDocument['data'],
    unknown
  >;
};

type TUseUser = {
  isLoading: boolean;
  isFetching: boolean;
  user: UserDocument | undefined;
};

export type TUseUpdateUser = {
  updateUser: UseMutateFunction<
    AxiosResponse<UserDocument>,
    AxiosError,
    UserDocument['data'],
    unknown
  >;
};

export const queryClient = new QueryClient();

type UseProjectsOptions = {
  orderByCreationDate?: 'asc' | 'desc';
};

export function useProjects({orderByCreationDate}: UseProjectsOptions = {}): TUseProjects {
  const {isLoading, isFetching, data} = useQuery<ProjectDocument[], AxiosError>(
    queryId.PROJECTS,
    async () => {
      const {data} = await axios.get(ApiEndpoints.PROJECTS);
      return data.projects;
    }
  );

  const projects = useMemo(() => {
    if (!data) {
      return [];
    } else if (!orderByCreationDate) {
      return data;
    } else {
      return data.sort(
        (p1, p2) =>
          (orderByCreationDate === 'asc' ? -1 : 1) *
          (new Date(p2.data.creationTime?.['@ts'] || 0).valueOf() -
            new Date(p1.data.creationTime?.['@ts'] || 0).valueOf())
      );
    }
  }, [data, orderByCreationDate]);

  return {
    isLoading,
    isFetching,
    projects,
  };
}

export function useProject(id = ''): TUseProject {
  if (id) {
    queryClient.removeQueries([queryId.PROJECT, ''], {exact: true});
  }

  const url = urljoin(ApiEndpoints.PROJECT, id);

  const {isLoading, isFetching, data} = useQuery<ProjectDocument, AxiosError>(
    [queryId.PROJECT, id],
    async () => {
      const {data} = await axios.get(url);
      return data;
    },
    {enabled: Boolean(id)}
  );

  return {
    isLoading,
    isFetching,
    project: data,
  };
}

export function useUpdateProject(id?: string): TUseUpdateProject {
  const {mutateAsync} = useMutation<
    AxiosResponse<ProjectDocument>,
    AxiosError,
    ProjectDocument['data']
  >(
    (data: ProjectDocument['data']) =>
      id ? axios.patch<ProjectDocument>(`/project/${id}`, data) : Promise.reject(),
    {
      onSuccess: ({data}) => {
        queryClient.invalidateQueries(queryId.PROJECTS, {exact: true});
        queryClient.setQueryData([queryId.PROJECT, id], data);
      },
    }
  );

  return {updateProject: mutateAsync};
}

export function useUser(id = ''): TUseUser {
  if (id) {
    queryClient.removeQueries([queryId.USER, ''], {exact: true});
  }

  const url = urljoin(ApiEndpoints.USER, id);

  const {isLoading, isFetching, data} = useQuery<UserDocument, AxiosError>(
    [queryId.USER, id],
    async () => {
      const {data} = await axios.get(url);
      return data;
    },
    {enabled: Boolean(id)}
  );

  return {
    isLoading,
    isFetching,
    user: data,
  };
}

export function useUpdateUser(id?: string): TUseUpdateUser {
  const {mutate} = useMutation<AxiosResponse<UserDocument>, AxiosError, UserDocument['data']>(
    (data: UserDocument['data']) =>
      id ? axios.patch<UserDocument>(`/user/${id}`, data) : Promise.reject(),
    {
      onSuccess: ({data}) => {
        queryClient.invalidateQueries(queryId.PROJECTS, {exact: true});
        queryClient.setQueryData([queryId.USER, id], data);
      },
    }
  );

  return {updateUser: mutate};
}
